import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import type { } from '@redux-devtools/extension' // required for devtools typing

import { ISigner } from 'models/signer';

interface SignState {
    signer?: ISigner;
    magicToken?: string;
    setSigner: (signer?: ISigner) => void;
    setToken: (token?: string) => void;
}

export const useSignStore = create<SignState>()(
    devtools(
        (set) => ({
            signer: undefined,
            magicToken: undefined,
            setSigner: (s) => set(() => ({ signer: s })),
            setToken: (t) => set(() => ({ magicToken: t })),
        }),
    ),
)
