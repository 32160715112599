import React, { PropsWithChildren } from 'react';
import { Result, Typography, Button } from 'antd';

interface IErrorBoundaryState {
    hasError: boolean;
    error: Error | null;
    info: object | null;
}

export class ErrorBoundary extends React.Component<PropsWithChildren, IErrorBoundaryState> {
    state: Readonly<IErrorBoundaryState> = {
        hasError: false,
        error: null,
        info: null,
    };

    static getDerivedStateFromError(): Partial<IErrorBoundaryState> {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error | null, info: object | null) {
        // You can also log the error to an error reporting service
        console.log('error was caught in the error boundary!', error, info);
        this.setState({ error, info });
    }

    onRefreshClick = () => {
        window.location.reload();
    }

    onResetClick = () => {
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.reload();
    };

    get infoItems() {
        if (!this.state.info) {
            return (
                <Typography.Text code style={{ whiteSpace: "pre-wrap" }}>No provided error information.</Typography.Text>
            );
        }

        if (!(this.state.info as any).componentStack) {
            return (
                <Typography.Text code style={{ whiteSpace: "pre-wrap" }}>{ JSON.stringify(this.state.info, null, 2) }</Typography.Text>
            );
        }

        const cs = (this.state.info as any).componentStack as string;
        return (
            <Typography.Text code style={{ whiteSpace: "pre-wrap" }}>{ cs }</Typography.Text>
        );
    }

    get errorResultDisplay() {
        return (
            <Result
                status="error"
                title="An Error Occurred"
                subTitle="Sadly, it appears we have an issue. The text below describes the problem. Please forward it to us so we can get it fixed."
                extra={[
                    <Button type="primary" key="reload" onClick={this.onRefreshClick}>
                        Refresh &amp; Try Again
                    </Button>,
                    <Button type="default" danger key="reset" onClick={this.onResetClick}>
                        Reset &amp; Log in
                    </Button>
                ]}
            >
                <div className="desc">
                    <Typography.Paragraph>
                        <Typography.Text strong style={{ fontSize: 16 }}>
                            Sadly, an error occurred while processing your request:
                        </Typography.Text>
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        <Typography.Text code style={{ marginBottom: 0 }}>{ this.state.error && this.state.error.message ? `Error: ${ this.state.error.message }` : 'Unknown error' }</Typography.Text>
                        { this.infoItems }
                    </Typography.Paragraph>
                </div>
            </Result>
        );
    }

    render() {
        if (this.state.hasError) {
            return this.errorResultDisplay;
        }

        return this.props.children;
    }
}
