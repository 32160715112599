import React, { PropsWithChildren } from 'react';
import { Layout, theme } from 'antd';

export const SignLayout: React.FC<PropsWithChildren> = ({ children }) => {
    const { token: { colorBgContainer, borderRadiusLG } } = theme.useToken();

    return (
        <Layout style={{ height: '100%', minHeight: '100vh' }}>
            <Layout.Header style={{ display: 'flex', alignItems: 'center' }}>
                <div className="logo" />
            </Layout.Header>

            <Layout.Content style={{ margin: '16px 0 0', padding: '0 48px' }}>
                <div
                    style={{
                        background: colorBgContainer,
                        minHeight: 280,
                        padding: 24,
                        borderRadius: borderRadiusLG,
                    }}
                >
                    {children}
                </div>

            </Layout.Content>

            <Layout.Footer style={{ textAlign: 'center' }}>
                Lendiom © {new Date().getFullYear()}
            </Layout.Footer>
        </Layout>
    );
}
