import { IRestRequest } from 'models/common/restRequest';
import { UnauthorizedError } from 'models/common/restResult';

export const BASE_URL = `${process.env.REACT_APP_API_URL}/v1`;

export function getBearerToken(token?: string): { Authorization: string } {
    if (!token) {
        return {
            Authorization: '',
        };
    }

    return {
        Authorization: `Bearer ${ token }`,
    };
}

const handleError = async (response: Response) => {
    //TODO: better error handling; actually throw an error and not random status codes when there is no message
    if (!response.ok) {
        console.log('handling error w/ status code:', response.status);

        if (response.status === 401) {
            throw new UnauthorizedError();
        }

        let message;
        try {
            message = await response.json();
        } catch (e) {
            throw response.status;
        }

        if (message) {
            throw message;
        } else {
            throw response.status;
        }
    }
}

const addAuthentication = (authenticationNeeded: boolean): { Authorization: string } => {
    if (authenticationNeeded) {
        return getBearerToken();
    }

    return { Authorization: '' };
}

const mapRequestToQuery = (req?: IRestRequest): string => {
    if (!req) {
        return '';
    }

    const query = new URLSearchParams();

    Object.keys(req).forEach((k) => {
        const val = req[k];
        if (!k || !val) {
            return;
        }

        if (typeof val === 'string' || typeof val === 'number') {
            query.append(k, `${ val }`);
        } else {
            val.forEach((f) => query.append(k, `${ f.key },${ f.value }`));
        }
    });

    return query.toString();
}

export const get = async <TResult>(url: string, authenticated = false, req?: IRestRequest, opts?: { [key: string]: any }): Promise<TResult | undefined> => {
    const config = {
        headers: {
            ...addAuthentication(authenticated),
            'X-Requested-With': 'LendiomSign',
        },
    };

    let fullUrl = BASE_URL + url;
    const query = mapRequestToQuery(req);
    if (query) {
        fullUrl = `${ fullUrl }?${ query }`;
    }

    const response = await fetch(fullUrl, config);

    await handleError(response);

    if (response.status === 204 || response.status === 202) {
        return undefined;
    }

    return response.json();
}

export const post = async <TBody, TResult>(url: string, body: TBody, authenticated = false, opts?: { [key: string]: any }): Promise<TResult | undefined> => {
    const config = {
        method: 'POST',
        headers: {
            ...addAuthentication(authenticated),
            'X-Requested-With': 'LendiomSign',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
    };

    const response = await fetch(BASE_URL + url, config);

    await handleError(response);

    if (response.status === 204 || response.status === 202) {
        return;
    }

    return response.json();
}

export const postForm = async <TResult>(url: string, body: FormData, authenticated = false, opts?: { [key: string]: any }): Promise<TResult | undefined> => {
    const config = {
        method: 'POST',
        headers: {
            ...addAuthentication(authenticated),
            'X-Requested-With': 'LendiomSign',
        },
        body,
    };

    const response = await fetch(BASE_URL + url, config);

    await handleError(response);

    if (response.status === 204 || response.status === 202) {
        return;
    }

    return response.json();
}

export const put = async <TBody, TResult>(url: string, body: TBody, authenticated = false, opts?: { [key: string]: any }): Promise<TResult | undefined> => {
    const config = {
        method: 'PUT',
        headers: {
            ...addAuthentication(authenticated),
            'X-Requested-With': 'LendiomSign',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }

    const response = await fetch(BASE_URL + url, config);

    await handleError(response);

    if (response.status === 204 || response.status === 202) {
        return;
    }

    return response.json();
}

export const del = async (url: string, authenticated = false, opts?: { [key: string]: any }) => {
    const config = {
        method: 'DELETE',
        headers: {
            ...addAuthentication(authenticated),
            'X-Requested-With': 'LendiomSign',
            'Content-Type': 'application/json'
        },
    };

    const response = await fetch(BASE_URL + url, config);

    await handleError(response);

    if (response.status === 204 || response.status === 202) {
        return;
    }

    return response.json();
}
