import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { App } from 'antd';
import { DocusealForm } from '@docuseal/react'

import { trackUmami } from 'utils/umami';
import { SignLayout } from 'components/layout';
import { logo } from 'utils/logo';

import { useSignStore } from 'store';
import { setConsentGiven } from 'api/documents';

export const SignView: React.FC = () => {
    const { modal } = App.useApp();
    const navigate = useNavigate();
    const [consented, setConsented] = useState<boolean>(false);
    const { signer, magicToken } = useSignStore((state) => state);

    useEffect(() => {
        trackUmami('Sign Viewed');
    }, []);

    useEffect(() => {
        if (consented) {
            return;
        }

        if (!signer) {
            modal.error({
                title: 'Whoops!',
                content: 'Uh oh! Appears you accessed this page directly, so we do not know who you are. How about we try it again from the start?',
                centered: true,
                okText: 'Back to the start',
                onOk: () => {
                    navigate('/');
                },
            });
            return;
        }

        modal.confirm({
            title: 'Consent Required',
            content: (
                <React.Fragment>
                    I, {signer?.name}, agree and understand that my electronic signature is legally equivalent to my manual or
                    handwritten signature, and I consent to be legally bound by this agreement. I further agree that my electronic
                    signature on this document is as valid as if I had signed it in writing. This agreement applies to the use of
                    electronic signatures on all forms requiring a signature in the future, should I choose to sign them electronically.<br /><br />
                    Under penalty of perjury, I herewith affirm that my electronic signature, and all future electronic signatures,
                    were signed by myself with full knowledge and consent and I am legally bound to these terms and conditions.
                </React.Fragment>
            ),
            cancelText: 'I Do Not Consent',
            okText: 'I Consent',
            centered: true,
            onOk: async () => {
                trackUmami('Consent: Granted');

                try {
                    setConsentGiven(magicToken!);
                    setConsented(true);
                } catch (e) {
                    console.error(e);
                }
            },
            onCancel: () => {
                trackUmami('Consent: Denied');
                navigate('/');
            },
        });
    }, [consented, magicToken, modal, navigate, signer]);

    const onComplete = useCallback(() => {
        trackUmami('Document Signed');

        modal.success({
            title: 'Congratulations!',
            content: 'Document signing completed. Thank you for signing the document. Please contact the business if you have any questions or would like a copy!',
            centered: true,
            okText: 'Return Home',
            onOk: () => {
                navigate('/');
            },
        });
    }, [modal, navigate]);

    const doc = useMemo(() => {
        if (!signer || !signer.link) {
            return null;
        }

        return (
            <DocusealForm
                src={signer.link}
                onComplete={onComplete}
                logo={logo}
                withTitle={true}
                withFieldNames={true}
                withSendCopyButton={false}
                withDownloadButton={false}
                allowToResubmit={false}
            />
        );
    }, [onComplete, signer]);

    return (
        <SignLayout>
            { doc }
        </SignLayout>
    );
};
