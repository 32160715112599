import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { App, Col, Layout, Row, Alert, Form, Space, Input, Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import { trackUmami } from 'utils/umami';
import { useSignStore } from 'store';

import { exchangeToken } from 'api/documents';

import './landing.css';

interface IFormValues {
    accessCode: string;
}

export const Landing: React.FC = () => {
    const { modal } = App.useApp();
    const navigate = useNavigate();
    const [form] = Form.useForm<IFormValues>();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const { setSigner, setToken } = useSignStore((state) => state);

    useEffect(() => {
        trackUmami('Landing Viewed');
        setSigner(undefined);
        setToken(undefined);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const modalDismiss = useCallback(() => {
        form.resetFields();
        setToken(undefined);
        setSigner(undefined);
        setSubmitting(false);
    }, [form, setSigner, setToken]);

    const onFinish = async (values: IFormValues) => {
        setSubmitting(true);

        trackUmami('Access Code Entered');

        try {
            const s = await exchangeToken(values.accessCode);
            if (!s) {
                throw new Error('Invalid Access Code');
            }

            setToken(values.accessCode);
            setSigner(s);

            switch (s.status) {
                case 'completed':
                    modal.info({
                        title: 'Already Signed',
                        content: 'It appears you have already signed the document. Please contact the sender if you have any questions or would like a copy.',
                        centered: true,
                        onOk: modalDismiss,
                    });
                    return;
                case 'expired':
                    modal.info({
                        title: 'Document Expired',
                        content: 'It appears the document has expired. Please contact the sender if you have any questions or feel like this is incorrect.',
                        centered: true,
                        onOk: modalDismiss,
                    });
                    return;
                case 'canceled':
                    modal.info({
                        title: 'Document Canceled',
                        content: 'The document has been canceled. Please contact the sender if you have any questions or feel like this is incorrect.',
                        centered: true,
                        onOk: modalDismiss,
                    });
                    return;
            }

            navigate('/sign');
        } catch (e: any) {
            console.error(e);
            setError(true);
            setSubmitting(false);
        }
    };

    return (
        <Layout className="landing">
            <Row justify="center" align={isMobileOnly ? 'middle' : 'top'}>
                <Col xs={22} sm={16} md={10} lg={6}>
                    <Layout.Content>
                        <div className="branding">
                            <img src="/logo.svg" alt="Lendiom" />
                        </div>

                        <Alert
                            type={error ? 'warning' : 'info'}
                            showIcon
                            message={error ? 'Invalid Access Code' : 'Welcome to Lendiom Sign!'}
                            description={error ? 'Uh oh! It appears the access code is not valid. Please try again.' : 'Please enter your access code to continue and sign the documents.'}
                            className="alert-msg"
                        />

                        <Form<IFormValues>
                            form={form}
                            onFinish={onFinish}
                            disabled={submitting}
                            className="login-form"
                        >
                            <Space.Compact block>
                                <Form.Item
                                    name="accessCode"
                                    noStyle
                                    rules={[{ required: true, message: 'Please enter your access code' }]}
                                >
                                    <Input placeholder="Enter access code here" />
                                </Form.Item>

                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={submitting}
                                >
                                    Continue <ArrowRightOutlined />
                                </Button>
                            </Space.Compact>
                        </Form>
                    </Layout.Content>
                </Col>
            </Row>
        </Layout>
    );
}
