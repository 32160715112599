import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App, Spin } from 'antd';

import { trackUmami } from 'utils/umami';
import { useSignStore } from 'store';

import { exchangeToken } from 'api/documents';

type IParams = {
    magicToken?: string
}

export const DocumentLoader: React.FC = () => {
    const { modal } = App.useApp();
    const navigate = useNavigate();
    const { magicToken } = useParams<IParams>();
    const { setSigner, setToken } = useSignStore((state) => state);

    useEffect(() => {
        trackUmami('Document Loader Viewed');
    }, []);

    const clear = useCallback(() => {
        setSigner(undefined);
        setToken(undefined);
        navigate('/');
    }, [navigate, setSigner, setToken]);

    const load = useCallback(async (token: string) => {
        try {
            const s = await exchangeToken(token);
            if (!s) {
                throw new Error('Invalid Access Code');
            }

            setToken(token);
            setSigner(s);

            switch (s.status) {
                case 'completed':
                    modal.info({
                        title: 'Already Signed',
                        content: 'It appears you have already signed the document. Please contact the sender if you have any questions or would like a copy.',
                        centered: true,
                        onOk: clear,
                    });
                    return;
                case 'expired':
                    modal.info({
                        title: 'Document Expired',
                        content: 'It appears the document has expired. Please contact the sender if you have any questions or feel like this is incorrect.',
                        centered: true,
                        onOk: clear,
                    });
                    return;
                case 'canceled':
                    modal.info({
                        title: 'Document Canceled',
                        content: 'The document has been canceled. Please contact the sender if you have any questions or feel like this is incorrect.',
                        centered: true,
                        onOk: clear,
                    });
                    return;
            }

            navigate('/sign');
        } catch (e: any) {
            console.error(e);

            modal.error({
                title: 'Oh my, an error!',
                content: 'Uh oh! An unknown occurred while trying to load the document. Please try again later. If the problem persists, please contact the sender. We apologize for the inconvenience.',
                centered: true,
                okText: 'Return Home',
                onOk: clear,
            });
        }
    }, [clear, modal, navigate, setSigner, setToken]);

    useEffect(() => {
        if (!magicToken) {
            return;
        }

        load(magicToken);
    }, [load, magicToken]);

    return (
        <Spin spinning />
    );
};
