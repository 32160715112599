import React, { useCallback, useEffect } from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';
import { Result, Button, Typography } from 'antd';

import { trackUmami } from 'utils/umami';

export const RouteNotFound: React.FC = () => {
    const navigate = useNavigate();
    const error = useRouteError();
    console.error(error);

    useEffect(() => {
        trackUmami('Route Not Found');
    }, []);

    const onRefreshClick = useCallback(() => {
        window.location.reload();
    }, []);

    const onResetClick = useCallback(() => {
        window.localStorage.clear();
        window.sessionStorage.clear();
        navigate("/");
    }, [navigate]);

    return (
        <Result
            status="error"
            title="Opps!"
            subTitle="Sorry, an unexpected error occurred while processing your request."
            extra={[
                <Button type="primary" key="reload" onClick={onRefreshClick}>
                    Refresh &amp; Try Again
                </Button>,
                <Button type="default" danger key="reset" onClick={onResetClick}>
                    Reset &amp; Log in
                </Button>
            ]}
        >
            <div className="desc">
                <Typography.Paragraph>
                    <Typography.Text strong style={{ fontSize: 16 }}>
                        Sadly, an error occurred while processing your request:
                    </Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph>
                    <Typography.Text code style={{ marginBottom: 0 }}>{(error as any).statusText || (error as any).message}</Typography.Text>
                </Typography.Paragraph>
            </div>
        </Result>
    );
};
