import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { App } from 'antd';

import { ErrorBoundary } from 'components/layout/errorBoundary';
import { RouteNotFound } from 'components/layout/routeNotFound';

import { registerUmamiScript } from 'utils/registerUmami';

import { Landing } from 'views/landing';
import { SignView } from 'views/sign';
import { DocumentLoader } from 'views/document-loader';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Landing />,
        errorElement: <RouteNotFound />,
    },
    {
        path: 'sign',
        element: <SignView />,
    },
    {
        path: 'document-load/:magicToken',
        element: <DocumentLoader />,
    },
]);

const AppRouter: React.FC = () => {
    useEffect(() => {
        registerUmamiScript('https://uem.fidetech.io/umami.js', process.env.REACT_APP_UMAMI_WEBSITE_ID!);
    }, []);

    return (
        <App>
            <ErrorBoundary>
                <RouterProvider router={router} />
            </ErrorBoundary>
        </App>
    );
}

export default AppRouter;
